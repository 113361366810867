import styled from 'styled-components'

const HrefButtonInverse = styled.a`
  display: inline-block;
  border: 0;
  padding: 1rem 1.25rem;
  background: transparent;
  color: white;
  box-shadow: inset 0px 0px 0px 2px white;
  border-radius: 2px;
  margin-bottom: 1rem;
  &:hover {
    color: ${ ({ theme }) => theme.jewel };
    background: white;
    text-decoration: none;
    cursor: pointer;
  }
`

export default HrefButtonInverse
