import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Primary, Alternate, Secondary } from '../../components/Section'
import { HrefButton, HrefButtonInverse, HrefButtonGhost } from '../Button'
import { calculateRem } from '../../utils/javascript-styles'
import LogoMJMDGlobal from '../../images/logo-mjmdglobal.svg'
import LogoFMD from '../../images/logo-fmd.png'
import IACPLogo from '../../images/iacp-logo.jpg'
import PartnershipsHero from './PartnershipsHero'
import { BackgroundSectionStyle } from '../Section/backgroundSection.css.js'
const StyledImg = styled.img`
  height: ${calculateRem(200)};
  margin: 0 auto;
`
const StyledIACPImg = styled.img`
  height: ${calculateRem(100)};
  margin: 0 auto;
`

const StyledParntershipsHero = styled(PartnershipsHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? 'center' : 'initial')};
  h1,
  h2,
  h3 {
    color: ${props => props.theme.white};
  }
`

const Partnerships = () => {
  return (
    <section>
      <StyledParntershipsHero backgroundHeight={550} centered>
        <h1>The Road to Incredible Partnerships!</h1>
      </StyledParntershipsHero>

      <Alternate centered>
        <Row>
          <Col xs={12}>
            <StyledImg src={LogoMJMDGlobal} alt="logo MJMD Global" />
            <h3>MJMD Global Management Group</h3>
            <p>
              MJMD Global Management Group manages and promotes product
              development and creative companies such as MJMD Mediation &
              Coaching. Their primary mission is to “Create New History!” A new
              energy, and new history is created with the making of MJMD
              Mediation & Coaching. “Go Touchless!” on your new journey with
              your new coach Dr. Michael J.
            </p>
            <HrefButtonGhost href="https://www.mjmdglobal.com/" target="_blank">
              Learn More
            </HrefButtonGhost>
          </Col>
        </Row>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <StyledImg src={LogoFMD} alt="Logo FMD Grand Foundation" />
            <h3>FMD Grand Foundation</h3>
            <p>
              Forever My Daddy Grand Foundation was born out of a strong desire
              to bring families who have suffered the effects of a broken
              marriage closer together. As a true advocate for the well-being of
              children who come from a broken home, Dr. Michael S. Joyner has
              written books, given lectures and now promotes an important
              initiative highlighting the significance of co-parenting while
              avoiding Parental Alienation.
            </p>
            <HrefButton href="http://www.fmdgrand.org/" target="_blank">
              Learn More
            </HrefButton>
          </Col>
        </Row>
      </Primary>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <StyledIACPImg
              src={IACPLogo}
              alt="Logo International Academy of Collaborative Professionals"
            />
            {/* <h3>IACP</h3> */}
            <p>
              IACP is the International Academy of Collaborative Professionals,
              an international community of legal, mental health and financial
              professionals working in concert to create client-centered
              processes for resolving conflict.
            </p>
            <HrefButton
              href="https://www.collaborativepractice.com/members/22357"
              target="_blank"
            >
              Learn More
            </HrefButton>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Partnerships
