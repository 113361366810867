import styled from 'styled-components';
import theme from '../../utils/theme';
import { remCal } from '../../utils/sass.utils';

const Button = styled.button`
  background: transparent;
  color: white;
  padding: 1rem 1.25rem;
  border-radius: ${remCal(3)};
  border: 0;
  box-shadow: inset 0px 0px 0px 2px white;
  transition: background 300ms;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background: white;
    color: black;
    box-shadow: inseet 0px 0px 2px #599e33;
  }
`;

export default Button;
