import styled from 'styled-components'

const ButtonGhostInverse = styled.button`
  border: 0;
  padding: 1rem 1.25rem;
  background: transparent;
  color: ${ props => props.theme.jewel };
  box-shadow: inset 0px 0px 0px 2px ${ props => props.theme.jewel };
  border-radius: 2px;
  &:hover {
    color: white;
    background: ${ props => props.theme.jewel };
    cursor: pointer;
  }
`

export default ButtonGhostInverse
