import React from 'react'
import Partnerships from '../components/Partnerships'
import MainLayout from '../components/MainLayout'

const PartnershipsPage = () => {
  return (
    <MainLayout>
      <Partnerships />
    </MainLayout>
  )
}

export default PartnershipsPage
