import styled from 'styled-components'
import { remCal } from '../../utils/sass.utils'

const HrefButton = styled.a`
  display: inline-block;

  padding: 1rem 1.25rem;
  background: ${ ({ theme }) => theme.jewel };
  border-radius: ${ remCal(3) };
  color: white;
  box-shadow: inset 0px 0px 0px 2px white;
  margin-bottom: 1rem;
  border: 1px solid white;
  &:hover {
    background: white;
    color: ${ ({ theme }) => theme.jewel };
    text-decoration: none;
    border: 1px solid ${ ({ theme }) => theme.jewel };
    cursor: pointer;
  }
`
export default HrefButton
